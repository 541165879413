// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-building-js": () => import("./../../../src/pages/building.js" /* webpackChunkName: "component---src-pages-building-js" */),
  "component---src-pages-building-stoves-details-js": () => import("./../../../src/pages/building-stoves-details.js" /* webpackChunkName: "component---src-pages-building-stoves-details-js" */),
  "component---src-pages-building-stoves-list-js": () => import("./../../../src/pages/building-stoves-list.js" /* webpackChunkName: "component---src-pages-building-stoves-list-js" */),
  "component---src-pages-car-loading-details-js": () => import("./../../../src/pages/car-loading-details.js" /* webpackChunkName: "component---src-pages-car-loading-details-js" */),
  "component---src-pages-car-loading-js": () => import("./../../../src/pages/car-loading.js" /* webpackChunkName: "component---src-pages-car-loading-js" */),
  "component---src-pages-car-loading-stoves-list-js": () => import("./../../../src/pages/car-loading-stoves-list.js" /* webpackChunkName: "component---src-pages-car-loading-stoves-list-js" */),
  "component---src-pages-check-in-js": () => import("./../../../src/pages/check-in.js" /* webpackChunkName: "component---src-pages-check-in-js" */),
  "component---src-pages-check-in-stoves-details-js": () => import("./../../../src/pages/check-in-stoves-details.js" /* webpackChunkName: "component---src-pages-check-in-stoves-details-js" */),
  "component---src-pages-check-in-stoves-list-js": () => import("./../../../src/pages/check-in-stoves-list.js" /* webpackChunkName: "component---src-pages-check-in-stoves-list-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-others-js": () => import("./../../../src/pages/others.js" /* webpackChunkName: "component---src-pages-others-js" */),
  "component---src-pages-painting-js": () => import("./../../../src/pages/painting.js" /* webpackChunkName: "component---src-pages-painting-js" */),
  "component---src-pages-painting-stoves-details-js": () => import("./../../../src/pages/painting-stoves-details.js" /* webpackChunkName: "component---src-pages-painting-stoves-details-js" */),
  "component---src-pages-painting-stoves-list-js": () => import("./../../../src/pages/painting-stoves-list.js" /* webpackChunkName: "component---src-pages-painting-stoves-list-js" */),
  "component---src-pages-parts-details-js": () => import("./../../../src/pages/parts-details.js" /* webpackChunkName: "component---src-pages-parts-details-js" */),
  "component---src-pages-parts-js": () => import("./../../../src/pages/parts.js" /* webpackChunkName: "component---src-pages-parts-js" */),
  "component---src-pages-parts-list-js": () => import("./../../../src/pages/parts-list.js" /* webpackChunkName: "component---src-pages-parts-list-js" */),
  "component---src-pages-pre-work-details-js": () => import("./../../../src/pages/pre-work-details.js" /* webpackChunkName: "component---src-pages-pre-work-details-js" */),
  "component---src-pages-pre-work-js": () => import("./../../../src/pages/pre-work.js" /* webpackChunkName: "component---src-pages-pre-work-js" */),
  "component---src-pages-pre-work-list-js": () => import("./../../../src/pages/pre-work-list.js" /* webpackChunkName: "component---src-pages-pre-work-list-js" */)
}

